//@ts-check
import {addEventListener} from '../utils/windowEvents.js';

const VISIBILITY  = 'visibilitychange';
const UNLOAD = 'unload';

/**
 * Get visibility attributes
 * @param {import('../utils/utils.js').State} state
 * @return {Promise<{entryType: 'visibility', type: string, startTime: number}>}
 */
export default function hidden([window]) {
    return new Promise(resolve => {
        const {document} = window;
        if (document.hidden) {
            return done(VISIBILITY, 0);
        }

        addEventListener(document, VISIBILITY, genHandler(VISIBILITY));
        addEventListener(window, UNLOAD, genHandler(UNLOAD));

        function genHandler(type) {
            return ({timeStamp}) => done(type, timeStamp);
        }
        function done(type, timeStamp) {
            resolve({
                entryType: 'visibility',
                type,
                startTime: timeStamp
            });
        }
    });
}
