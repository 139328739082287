//@ts-check
import observe from './observe.js';

/**
 * @callback promisifiedObserverCallback
 * @param {PerformanceEntryList} entries 
 * @param {(r:*) => void} finish - and report
 * @returns {void}
 */

/**
 * Wrap PerformanceObserver in a promise
 * @param {function} PerformanceObserver 
 * @param {string} entryType 
 * @param {promisifiedObserverCallback} cb 
 * @param {boolean} [buffered]
 */
export default function promisifyObserver(PerformanceObserver, entryType, cb, buffered) {
    return new Promise((resolve, reject) => {
        const observer = observe(PerformanceObserver, entryType, entries => cb(entries, result => {
            observer.disconnect();
            resolve(result);
        }), buffered);
        if (!observer) {
            reject(entryType);
        }
    });
}
