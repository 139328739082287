//@ts-check
import {getFirstEntryByType, isNumber} from '../utils/utils.js';
import {fixURL} from '../utils/consent.js';

const entryType = 'navigation';

/**
 * @param {import('../utils/utils.js').State} state
 */
export default function navStart([window, performance]) {
    /** @type {any} */
    let result;

    /** @type {any} */
    let navigation = getFirstEntryByType(performance, entryType);
    if (navigation) {
        result = {
            navigationType: navigation.type,
            protocol: navigation.nextHopProtocol
        };
    } else {
        navigation = performance.timing;
        result = {};
    }

    const {navigationStart = 0, fetchStart, domainLookupStart, domainLookupEnd, redirectStart, redirectEnd, connectStart, connectEnd, secureConnectionStart, requestStart, responseStart} = navigation;
    if (isNumber(fetchStart)) {
        result.fetchStart = fetchStart - navigationStart;
    }

    result.dns = domainLookupEnd - domainLookupStart;
    const redirect = redirectEnd - redirectStart;
    if (redirect) {
        result.redirect = redirect;
    }

    if (secureConnectionStart) {
        result.tcp = secureConnectionStart - connectStart;
        result.ssl = connectEnd - secureConnectionStart;
    } else {
        result.tcp = connectEnd - connectStart;
    }

    result.ttfb = responseStart - navigationStart;
    result.response = responseStart - requestStart;

    const {document: {referrer}, location: {href}} = window;
    result.url = fixURL(href, window);
    if (referrer) {
        result.referrer = fixURL(referrer, window);
    }

    return Promise.resolve(result);
}
